import * as ZhCn from 'blockly/msg/zh-hans'

export default {
  ...ZhCn,
  CATEGORY_CONTROLS: '控制',
  CATEGORY_LOGIC: '逻辑',
  CATEGORY_MATH: '数学',
  CATEGORY_VARIABLES: '变量',
  CATEGORY_FUNCTIONS: '函数',
  CATEGORY_ACT: '扮演',
  CATEGORY_INTERACTIONS: '交互',
  CATEGORY_DATA: '数据',
}
